import React from "react"
import Layout from "../components/layout"
import ServiceSelector from "../components/ServiceSelector"
import Seo from "../components/seo"

const UslugiIndexPage = () => {
  return (
    <Layout>
      <Seo title="Услуги" />
      <div className="py-10 mx-auto max-w-7xl">
        <ServiceSelector />
      </div>
    </Layout>
  )
}

export default UslugiIndexPage
